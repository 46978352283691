
<template>
  <span style="color: rgb(191, 68, 39);font-weight: 700">Selecione as contas que este usuário terá acesso:</span>
  <va-alert
    class="mt-3"
    color="#bf4427"
    outline
  >
    <span>
      Contas selecionadas:
      <va-chip
        v-for="item in selectedItemsEmitted"
        :key="item.id"
        class="ml-2"
        @click="unselectItem(item)"
      >
        {{ item.name }}
      </va-chip>
    </span>
  </va-alert>
  <va-data-table
    v-model="selectedItems"
    :items="items"
    :columns="columns"
    selectable="true"
    select-mode="multiple"
    selected-color="#e08a2c"
    no-data-html="Sem resultados"
    style="height: 300px; overflow-y: scroll"
    @selectionChange="change($event.currentSelectedItems)"
  />
</template>

<script>
import {defineComponent, toRaw} from 'vue'

export default defineComponent({
  props: {
    defaultSelected: {
      type: [String],
      default: '',
    },
    accounts: {
      type: [String],
      default: ''
    }
  },

  emits: ['changeselected'],
  data () {
    const columns = [
      // { key: 'id', sortable: true, label: 'ID' },
      { key: 'name', sortable: true, label: 'Nome da conta' },
      { key: 'cnpj', sortable: true, label: 'CNPJ' },
    ]

    return {
      items: this.accounts,
      columns,
      selectable: true,
      selectedItems: [],
      selectedItemsEmitted: [],
      selectMode: 'multiple',
      selectedColor: '#888888',
      selectModeOptions: ['single', 'multiple'],
      selectColorOptions: ['primary', 'danger', 'warning', '#888888'],
    }
  },

  watch: {
    accounts (){
      this.items = this.accounts;
    },
  },
  mounted() {

    if(this.defaultSelected){
      this.selectedItems = this.items.filter(item => {
        return this.defaultSelected.includes(item.id)
      })
    }
  },
  methods: {
    unselectItem (item) {
      this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem !== item)
    },
    change (ev) {
      this.selectedItemsEmitted = ev;
      this.$emit("changeselected", this.selectedItems.map(item => toRaw(item)));
    }
  }
})
</script>
